<template>
  <div>
    <customer-create-page></customer-create-page>
  </div>
</template>

<script>
import CustomerCreatePage from './CustomerCreatePage.vue';

export default {
  components: {
    CustomerCreatePage,
  },

  data() {
    return {};
  },
};
</script>
