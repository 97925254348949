<template>
  <b-overlay :show="formShow" rounded="sm" no-fade>
    <b-card>
      <b-row>
        <table class="table table-sm">
          <thead>
            <tr>
              <th scope="col">Customer Details</th>
            </tr>
          </thead>
        </table>
      </b-row>

      <validation-observer ref="customerRules">
        <b-form>
          <b-row class="mt-2">
            <b-col cols="12" sm="6" md="4" xl="3">
              <b-form-group>
                <label for="some-radios" class="border-bottom">Seller Type* </label>
                <validation-provider name="Seller Type" #default="{ errors }" rules="required">
                  <b-form-radio-group class="border-bottom pb-1">
                    <b-form-radio v-model="customerData.sellerType" :state="errors.length > 0 ? false : null" name="seller-type" class="mr-2 " value="1">
                      Individual
                    </b-form-radio>
                    <b-form-radio v-model="customerData.sellerType" :state="errors.length > 0 ? false : null" name="seller-type" class="mr-2 " value="2">
                      Business
                    </b-form-radio>
                  </b-form-radio-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="12" sm="6" md="4" xl="3">
              <b-form-group>
                <label v-if="customerData.sellerType == 2" for="firstName">Business Name*</label>
                <label v-else for="firstName">First Name*</label>
                <validation-provider :name="customerData.sellerType == 2 ? 'Business Name' : 'First Name'" #default="{ errors }" rules="required">
                  <b-form-input id="firstName" v-model="customerData.firstName" :state="errors.length > 0 ? false : null" type="text" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="12" sm="6" md="4" xl="3">
              <b-form-group>
                <label v-if="customerData.sellerType == 2" for="lastName">Operating Name</label>
                <label v-else for="lastName">Last Name </label>
                <b-form-input id="lastName" v-model="customerData.lastName" type="text" />
              </b-form-group>
            </b-col>

            <b-col cols="12" sm="6" md="4" xl="3" v-if="customerData.sellerType == 2">
              <b-form-group>
                <label for="contactPerson">Contact Person</label>

                <b-form-input id="contactPerson" v-model="customerData.contactPerson" type="text" />
              </b-form-group>
            </b-col>

            <b-col cols="12" sm="6" md="4" xl="3" v-if="customerData.sellerType == 2">
              <b-form-group>
                <label for="contactInfo">Contact Info</label>
                <b-form-input id="contactInfo" v-model="customerData.contactInfo" type="text" />
              </b-form-group>
            </b-col>

            <b-col cols="12" sm="6" md="4" xl="3">
              <b-form-group>
                <label for="driverLicenseNumber">Driver's License Number</label>
                <b-form-input id="driverLicenseNumber" v-model="customerData.driverLicenseNumber" type="text" />
              </b-form-group>
            </b-col>

            <b-col cols="12" sm="6" md="4" xl="3">
              <b-form-group>
                <label for="gst">G.S.T Registration</label>
                <b-form-input id="gst" v-model="customerData.gst" type="text" />
              </b-form-group>
            </b-col>

            <b-col cols="12" sm="6" md="4" xl="3">
              <b-form-group>
                <label for="phoneNumber">Phone Number*</label>
                <validation-provider name="Phone Number" #default="{ errors }" :rules="wherefrom == 'customer-edit-purchase' || wherefrom == 'customer-edit-sale' || wherefrom == 'customer-edit-inception' ? 'required' : ''">
                  <b-form-input id="phoneNumber" v-model="customerData.phoneNumber" :state="errors.length > 0 ? false : null" type="text" />
                  <small class="text-danger" v-if="customerData.phoneNumber == null && (wherefrom == 'customer-edit-purchase' || wherefrom == 'customer-edit-sale' || wherefrom == 'customer-edit-inception')">The Phone Number field is required </small>
                  <small class="text-danger" v-else>{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="12" sm="6" md="4" xl="3">
              <b-form-group>
                <label for="email">Email*</label>
                <validation-provider name="Email" #default="{ errors }" :rules="wherefrom == 'customer-edit-purchase' || wherefrom == 'customer-edit-sale' || wherefrom == 'customer-edit-inception' ? 'email|required' : 'email'">
                  <b-form-input id="email" v-model="customerData.email" :state="errors.length > 0 ? false : null" type="text" />
                  <small class="text-danger" v-if="customerData.email == null && (wherefrom == 'customer-edit-purchase' || wherefrom == 'customer-edit-sale' || wherefrom == 'customer-edit-inception')">The Email field is required </small>
                  <small class="text-danger" v-else>{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="12" sm="6" md="4" xl="3">
              <b-form-group>
                <label for="otherIdNumber">Other ID Number</label>
                <b-form-input id="otherIdNumber" v-model="customerData.otherIdNumber" type="text" />
              </b-form-group>
            </b-col>

            <b-col cols="12" sm="6" md="4" xl="3">
              <b-form-group>
                <label for="pst">P.S.T Registration</label>
                <b-form-input id="pst" v-model="customerData.pst" type="text" />
              </b-form-group>
            </b-col>

            <b-col cols="12" sm="6" md="4" xl="3">
              <b-form-group>
                <label for="unitNumber">Unit Number</label>
                <b-form-input id="unitNumber" v-model="customerData.unitNumber" type="text" />
              </b-form-group>
            </b-col>

            <b-col cols="12" sm="6" md="4" xl="6">
              <b-form-group>
                <label for="streetAddress">Street Address*</label>
                <validation-provider name="Street Address" #default="{ errors }" :rules="wherefrom == 'customer-edit-purchase' || wherefrom == 'customer-edit-sale' || wherefrom == 'customer-edit-inception' ? 'required' : ''">
                  <b-form-input id="streetAddress" v-model="customerData.streetAddress" :state="errors.length > 0 ? false : null" type="text" />
                  <small class="text-danger" v-if="customerData.streetAddress == null && (wherefrom == 'customer-edit-purchase' || wherefrom == 'customer-edit-sale' || wherefrom == 'customer-edit-inception')">The Street Address field is required </small>
                  <small class="text-danger" v-else>{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="12" sm="6" md="4" xl="3">
              <b-form-group>
                <label for="dateOfBirth">Date of Birth</label>
                <flat-pickr id="dateOfBirth" class="form-control" :config="{ dateFormat: 'd-m-Y' }" placeholder="DD-MM-YYYY" v-model="customerData.dateOfBirth" />
              </b-form-group>
            </b-col>

            <b-col cols="12" sm="6" md="4" xl="3">
              <b-form-group>
                <label for="dealer">Dealer Registration</label>
                <b-form-input id="dealer" v-model="customerData.dealer" type="text" />
              </b-form-group>
            </b-col>

            <b-col cols="12" sm="6" md="4" xl="3">
              <b-form-group>
                <label for="city">City*</label>
                <validation-provider name="City" #default="{ errors }" :rules="wherefrom == 'customer-edit-purchase' || wherefrom == 'customer-edit-sale' || wherefrom == 'customer-edit-inception' ? 'required' : ''">
                  <b-form-input id="city" v-model="customerData.city" :state="errors.length > 0 ? false : null" type="text" />
                  <small class="text-danger" v-if="customerData.city == null && (wherefrom == 'customer-edit-purchase' || wherefrom == 'customer-edit-sale' || wherefrom == 'customer-edit-inception')">The City field is required </small>
                  <small class="text-danger" v-else>{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="12" sm="6" md="4" xl="3">
              <b-form-group>
                <label for="provence">Province*</label>
                <validation-provider name="Provence" #default="{ errors }" :rules="wherefrom == 'customer-edit-purchase' || wherefrom == 'customer-edit-sale' || wherefrom == 'customer-edit-inception' ? 'required' : ''">
                  <b-form-input id="provence" v-model="customerData.provence" :state="errors.length > 0 ? false : null" type="text" />
                  <small class="text-danger" v-if="customerData.provence == null && (wherefrom == 'customer-edit-purchase' || wherefrom == 'customer-edit-sale' || wherefrom == 'customer-edit-inception')">The Provence field is required </small>
                  <small class="text-danger" v-else>{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="12" sm="6" md="4" xl="3">
              <b-form-group>
                <label for="postalCode">Postal Code*</label>
                <validation-provider name="Postal Code" #default="{ errors }" :rules="wherefrom == 'customer-edit-purchase' || wherefrom == 'customer-edit-sale' || wherefrom == 'customer-edit-inception' ? 'required' : ''">
                  <b-form-input id="postalCode" v-model="customerData.postalCode" :state="errors.length > 0 ? false : null" type="text" />
                  <small class="text-danger" v-if="customerData.postalCode == null && (wherefrom == 'customer-edit-purchase' || wherefrom == 'customer-edit-sale' || wherefrom == 'customer-edit-inception')">The Postal Code field is required </small>
                  <small class="text-danger" v-else>{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="12" sm="6" md="4" xl="3">
              <b-form-group>
                <label for="country">Country*</label>
                <validation-provider name="Country" #default="{ errors }" :rules="wherefrom == 'customer-edit-purchase' || wherefrom == 'customer-edit-sale' || wherefrom == 'customer-edit-inception' ? 'required' : ''">
                  <b-form-input id="country" v-model="customerData.country" :state="errors.length > 0 ? false : null" type="text" />
                  <small class="text-danger" v-if="customerData.country == null && (wherefrom == 'customer-edit-purchase' || wherefrom == 'customer-edit-sale' || wherefrom == 'customer-edit-inception')">The Country field is required </small>
                  <small class="text-danger" v-else>{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-button v-if="id == null" type="submit" @click.prevent="formSubmitted" variant="primary" class="mr-1 float-right">
                Save
              </b-button>
              <b-button v-else type="submit" @click.prevent="formSubmitted" v-show="customerData.archive == 'no'" variant="primary" class="mr-1 float-right">
                Save Changes
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
    <b-card v-if="id > 0">
      <b-row v-show="customerData.archive == 'no'">
        <b-col>
          <b-alert v-if="balance == 0" variant="info" show>
            <h4 class="alert-heading">Balance : {{ formatPrice(balance) }}</h4>
          </b-alert>

          <b-alert v-else :variant="balance > 0 ? 'success' : 'danger'" show>
            <h4 class="alert-heading">Balance : {{ formatPrice(balance) }}</h4>
          </b-alert>
        </b-col>


        <b-col>
          <b-alert v-if="lienDue == 0" variant="warning" show>
            <h4 class="alert-heading">Lien Payout Receivable : {{ formatPrice(lienDue) }}</h4>
          </b-alert>

          <b-alert v-else :variant="lienDue > 0 ? 'info' : 'danger'" show>
            <h4 class="alert-heading">Lien Payout Receivable : {{ formatPrice(lienDue) }}</h4>
          </b-alert>
        </b-col>

      </b-row>

      <b-row>
        <div v-for="quote in customerData.trades" :key="quote.id">
          <b-card class=" mb-1 ml-2 border" v-if="customerData.id == quote.purchaserId">
            <div class="business-items mt-2  ">
              <div class="business-item">
                <div class="d-flex align-items-center justify-content-between">
                  <h5>{{ quote.value }}</h5>
                </div>

                <div class="d-flex align-items-center justify-right mt-1  ">
                  <b-badge variant="success">Appraised Vehicle Price {{ formatPrice(quote.appraisedVehiclePrice ? quote.appraisedVehiclePrice : 0) }} </b-badge>

                  <b-badge variant="info" class="ml-2">Credit Due {{ formatPrice(quote.creditDue ? quote.creditDue : 0) }} </b-badge>

                  <b-badge variant="primary" class="ml-2">Lien Payout Due {{ formatPrice(quote.lienDue ? quote.lienDue : 0) }} </b-badge>

                </div>
              </div>
            </div>
          </b-card>
        </div>
      </b-row>
    </b-card>

    <!-- History -->

    <b-card v-if="id > 0">
      <b-row>
        <table class="table table-sm">
          <thead>
            <tr>
              <th scope="col">CUSTOMER HISTORY</th>
            </tr>
          </thead>
        </table>
      </b-row>

      <b-row class="mt-2">
        <table class="table table-responsive table-sm  table-striped table-bordered text-center ">
          <thead class="thead-dark">
            <tr>
              <th style="white-space: nowrap;" class="thead-dark">Date</th>
              <th style="white-space: nowrap;" class="thead-dark">User</th>
              <th style="white-space: nowrap;" class="thead-dark">Seller Type</th>
              <th style="white-space: nowrap;" class="thead-dark">First Name</th>
              <th style="white-space: nowrap;" class="thead-dark">Last Name</th>
              <th style="white-space: nowrap;" class="thead-dark">Driver's License Number</th>
              <th style="white-space: nowrap;" class="thead-dark">G.S.T Registration</th>
              <th style="white-space: nowrap;" class="thead-dark">Phone Number</th>
              <th style="white-space: nowrap;" class="thead-dark">Email</th>
              <th style="white-space: nowrap;" class="thead-dark">Other ID Number</th>
              <th style="white-space: nowrap;" class="thead-dark">P.S.T Registration</th>
              <th style="white-space: nowrap;" class="thead-dark">Unit Number</th>
              <th style="white-space: nowrap;" class="thead-dark">Street Address</th>
              <th style="white-space: nowrap;" class="thead-dark">Date of Birth</th>
              <th style="white-space: nowrap;" class="thead-dark">Dealer Registration</th>
              <th style="white-space: nowrap;" class="thead-dark">City</th>
              <th style="white-space: nowrap;" class="thead-dark">Province</th>
              <th style="white-space: nowrap;" class="thead-dark">Postal Code</th>
              <th style="white-space: nowrap;" class="thead-dark">Country</th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="(data, x) in customerData.historys" :key="x">
              <th style="white-space: nowrap;">{{ dateFormat(data.created_at) }}</th>
              <td style="white-space: nowrap;">{{ data.userName }}</td>
              <td v-if="data.valueJson.sellerType == 1">Individual</td>
              <td v-else>Business</td>
              <td>{{ data.valueJson.firstName }}</td>
              <td>{{ data.valueJson.lastName }}</td>
              <td>{{ data.valueJson.driverLicenseNumber }}</td>
              <td>{{ data.valueJson.gst }}</td>
              <td>{{ data.valueJson.phoneNumber }}</td>
              <td>{{ data.valueJson.email }}</td>
              <td>{{ data.valueJson.otherIdNumber }}</td>
              <td>{{ data.valueJson.pst }}</td>
              <td>{{ data.valueJson.unitNumber }}</td>
              <td>{{ data.valueJson.streetAddress }}</td>
              <td>{{ data.valueJson.dateOfBirth }}</td>
              <td>{{ data.valueJson.dealer }}</td>
              <td>{{ data.valueJson.city }}</td>
              <td>{{ data.valueJson.provence }}</td>
              <td>{{ data.valueJson.postalCode }}</td>
              <td>{{ data.valueJson.country }}</td>
            </tr>
          </tbody>
        </table>
      </b-row>
    </b-card>
    <!-- History -->

    <b-card v-if="id > 0">
      <b-row>
        <table class="table table-sm">
          <thead>
            <tr>
              <th scope="col">TRADE-IN TRANSACTIONS</th>
            </tr>
          </thead>
        </table>
      </b-row>

      <b-table striped hover :bordered="true" class="position-relative text-center mt-2" selectable responsive :small="true" head-variant="dark" show-empty empty-text="No matching records found" :fields="balanceFields" :items="customerData.balanceTable">
        <template #cell(created_at)="row">
          <strong class="text-primary">{{ dateFormat(row.item.created_at) }}</strong>
        </template>

        <template #cell(purchaseId)="row">
          <span>{{ row.item.purchaseId ? row.item.purchaseId : 'N/A' }}</span>
        </template>

        <template #cell(saleQuoteId)="row">
          <span>{{ row.item.saleQuoteId ? row.item.saleQuoteId : 'N/A' }}</span>
        </template>

        <template #cell(cost)="row">
          <span>$ {{ row.item.cost ? formatPrice(row.item.cost, 2) : formatPrice(0, 2) }}</span>
        </template>

        <template #cell(lienReceivable)="row">
          <span>$ {{ row.item.lienReceivable ? formatPrice(row.item.lienReceivable, 2) : formatPrice(0, 2) }}</span>
        </template>

        <template #cell(lienGoodDate)="row">
          <span> {{ row.item.lienGoodDate ? row.item.lienGoodDate : 'N/A' }}</span>
        </template>

        <template #cell(status)="row">
          <span v-if="row.item.status == 'A' || row.item.status == 'B'">
            <span v-if="row.item.cost > 0">
              <b-badge pill variant="success" class="badge-glow">CREDIT</b-badge>
            </span>

            <span v-else>
              <b-badge pill variant="warning" class="badge-glow">DEBIT</b-badge>
            </span>
          </span>

          <span v-if="row.item.status == 'C'">
            <b-badge pill variant="danger" class="badge-glow">CANCELED</b-badge>
          </span>
        </template>
      </b-table>
    </b-card>

    <b-card v-if="id > 0">
      <b-row>
        <table class="table table-sm">
          <thead>
            <tr>
              <th scope="col">Authorized Users</th>
            </tr>
          </thead>
        </table>
      </b-row>

      <b-table striped hover :bordered="true" class="position-relative text-center mt-2" selectable responsive :small="true" head-variant="dark" show-empty empty-text="No matching records found" :fields="quoteFields" :items="customerData.users">
        <template #cell(id)="row">
          <strong class="text-primary">#{{ Number(row.item.id) }}</strong>
        </template>

        <template #cell(firstName)="row">
          <span>{{ row.item.name ? row.item.name : 'N/A' }}</span>
        </template>

        <template #cell(lastName)="row">
          <span>{{ row.item.surname ? row.item.surname : 'N/A' }}</span>
        </template>

        <template #cell(email)="row">
          <span>{{ row.item.email ? row.item.email : 'N/A' }}</span>
        </template>
      </b-table>
    </b-card>
  </b-overlay>
</template>

<script>
import { BForm, BCard, BContainer, BFormFile, BTabs, BTab, BBadge, BListGroupItem, BListGroup, BNavItem, BFormTextarea, BFormRadioGroup, BInputGroup, BFormRadio, BAlert, BButton, BOverlay, BRow, BCol, BFormGroup, BFormInput, BFormInvalidFeedback } from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
import customerStoreModule from '../customerStoreModule';
import store from '@/store';
import vSelect from 'vue-select';
import { onUnmounted } from '@vue/composition-api';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import 'vue-form-wizard/dist/vue-form-wizard.min.css';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import flatPickr from 'vue-flatpickr-component';
import VueNumericInput from 'vue-numeric-input';
import BCardActions from '@core/components/b-card-actions/BCardActions.vue';
import { required, email } from '@validations';
import router from '@/router';
import Cleave from 'vue-cleave-component';
import 'cleave.js/dist/addons/cleave-phone.us';
import moment from 'moment';

export default {
  components: {
    BCard,
    BForm,
    Cleave,

    BContainer,
    BFormFile,
    BTabs,
    BTab,
    flatPickr,
    BBadge,
    BListGroupItem,
    BListGroup,
    BNavItem,
    BCardActions,
    BFormTextarea,
    BFormRadioGroup,
    BInputGroup,
    BFormRadio,
    BAlert,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BOverlay,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    vSelect,
    ToastificationContent,
    VueNumericInput,
  },
  directives: {
    Ripple,
  },

  setup() {
    const CUSTOMER_APP_STORE_MODULE_NAME = 'customer';
    // Register module
    if (!store.hasModule(CUSTOMER_APP_STORE_MODULE_NAME)) store.registerModule(CUSTOMER_APP_STORE_MODULE_NAME, customerStoreModule);
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CUSTOMER_APP_STORE_MODULE_NAME)) store.unregisterModule(CUSTOMER_APP_STORE_MODULE_NAME);
    });
  },

  computed: {},

  created() {
    this.getByIdCustomer();
  },

  data() {
    var customerDataModel = {
      sellerType: null,
      phoneNumber: null,
      firstName: null,
      lastName: null,
      email: null,
      unitNumber: null,
      provence: null,
      streetAddress: null,
      city: null,
      postalCode: null,
      country: null,
      gst: null,
      pst: null,
      dealer: null,
      driverLicenseNumber: null,
      otherIdNumber: null,
      dateOfBirth: null,
      contactPerson: null,
      contactInfo: null,
    };

    var wherefrom = router.currentRoute.name;

    return {
      balance: 0,
      lienDue : 0,
      quoteFields: [
        { key: 'id', label: 'USER ID', class: 'text-center', thStyle: 'width: 20%' },
        { key: 'firstName', label: 'FIRST NAME', class: 'text-center', thStyle: 'width: 25%' },
        { key: 'lastName', label: 'LAST NAME', class: 'text-center', thStyle: 'width: 25%' },
        { key: 'email', label: 'EMAIL', class: 'text-center', thStyle: 'width: 30%' },
      ],

      balanceFields: [
        { key: 'created_at', label: 'CREATE DATE', class: 'text-center', thStyle: 'width: 10%' },
        { key: 'purchaseId', label: 'PURCHASE QUOTE', class: 'text-center', thStyle: 'width: 10%' },
        { key: 'saleQuoteId', label: 'SALE QUOTE', class: 'text-center', thStyle: 'width: 10%' },
        { key: 'cost', label: 'AMOUNT', class: 'text-center', thStyle: 'width: 20%' },
        { key: 'lienReceivable', label: 'LIEN PAYOUT RECEIVABLE', class: 'text-center', thStyle: 'width: 20%' },
        { key: 'lienGoodDate', label: 'LIEN GOOD UNTIL', class: 'text-center', thStyle: 'width: 20%' },
        { key: 'status', label: 'STATUS', class: 'text-center', thStyle: 'width: 10%' },
      ],

      options: {
        creditCard: {
          creditCard: true,
        },
        date: {
          date: true,
          delimiter: '-',
          datePattern: ['Y', 'm', 'd'],
        },
        phone: {
          phone: true,
          phoneRegionCode: 'US',
        },
        time: {
          time: true,
          timePattern: ['h', 'm', 's'],
        },
        number: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
        },
        block: {
          blocks: [4, 3, 3],
          uppercase: true,
        },
        delimiter: {
          delimiter: '·',
          blocks: [3, 3, 3],
          uppercase: true,
        },
        customDelimiter: {
          delimiters: ['.', '.', '-'],
          blocks: [3, 3, 3, 2],
          uppercase: true,
        },
        prefix: {
          prefix: '+63',
          blocks: [3, 3, 3, 4],
          uppercase: true,
        },

        percent: {
          numeral: true,
          numeralPositiveOnly: true,
          blocks: [2],
          prefix: '% ',
          rawValueTrimPrefix: true,
          numeralIntegerScale: 2,
        },
      },

      id: null,
      formShow: false,
      baseURL: store.state.app.baseURL,
      customerData: customerDataModel,
      required,
      email,
      wherefrom,
    };
  },

  methods: {
    dateFormat(val) {
      if (val != undefined) {
        return moment(String(val)).format('DD/MM/YYYY hh:mm');
      }
    },

    formSubmitted() {
      this.formShow = true;

      this.$refs.customerRules.validate().then((success) => {
        if (success) {
          if (router.currentRoute.params.id) {
            store.dispatch('customer/updateCustomer', this.customerData).then((response) => {
              if (response.data != 'alreadyUpdated') {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Successful',
                    text: '✔️ Customer Update Successful',
                    icon: 'ThumbsUpIcon',
                    variant: 'success',
                  },
                });

                if (router.currentRoute.name == 'customer-edit-purchase') {
                  router.push({ name: 'quote-preview', params: { id: router.currentRoute.params.quoteid } });
                } else if (router.currentRoute.name == 'customer-edit-sale') {
                  router.push({ name: 'sales-preview', params: { id: router.currentRoute.params.quoteid } });
                } else if (router.currentRoute.name == 'customer-edit-inception') {
                  router.push({ name: 'testlease-preview', params: { id: router.currentRoute.params.quoteid } });
                } else if (router.currentRoute.name == 'customer-add-purchase') {
                  router.push({ name: 'quote-add', params: { id: router.currentRoute.params.quoteid } });
                } else if (router.currentRoute.name == 'purchase-customer-edit') {
                  router.push({ name: 'quote-add', params: { id: router.currentRoute.params.quoteid } });
                } else {
                  this.balance = response.data.balance;
                  this.customerData = response.data;
                }
                this.formShow = false;
              } else {
                this.formShow = false;
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Please refresh the page.',
                    text: '⚠️ Data has been changed during operations. ',
                    icon: 'RefreshCwIcon',
                    variant: 'warning',
                  },
                });
              }
            });
          } else {
            store.dispatch('customer/saveCustomer', this.customerData).then((response) => {
              if (response.status == 201) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Successful',
                    text: '✔️ Customer Insert Successful',
                    icon: 'ThumbsUpIcon',
                    variant: 'success',
                  },
                });
                // this.getByIdCustomer(response.data.id);

                if (router.currentRoute.name == 'customer-add-purchase') {
                  router.push({ name: 'quote-add', params: { id: router.currentRoute.params.quoteid } });
                } else {
                  router.push({ name: 'customer-edit', params: { id: response.data.id } });
                }
              }
            });
          }
        } else {
          this.formShow = false;
        }
      });
    },

    dateFormat(val) {
      return moment(String(val)).format('DD-MM-YYYY HH:mm');
    },

    formatPrice(value, val) {
      if (value != null) {
        let val = (value / 1).toFixed(2).replace(',', '.');
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      }
    },

    getByIdCustomer(optionalId = 0) {
      this.formShow = true;

      if (router.currentRoute.params.id || optionalId > 0) {
        this.id = router.currentRoute.params.id ?? optionalId;
        store
          .dispatch('customer/getCustomerById', { id: this.id })
          .then((response) => {
            // response.data.balanceTable = response.data.balanceTable.reverse();
            this.balance = response.data.balance;
            this.lienDue = response.data.lienDue;

            this.customerData = response.data;

            this.formShow = false;
          })
          .catch((error) => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Something went wrong',
                text: 'Please try again or report an issue to support',
                icon: 'ThumbsDownIcon',
                variant: 'danger',
              },
            });
            // router.push({ name: 'quote-list' });
          });
      } else {
        this.formShow = false;
      }
    },

    toastMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Successful',
          text: '✔️ ' + val + ' Add Successful',
          icon: 'ThumbsUpIcon',
          variant: 'success',
        },
      });
    },

    validationForm1() {
      return new Promise((resolve, reject) => {
        this.$refs.form1.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
